import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BraintreeHostedFieldsComponent } from './braintree-hosted-fields.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CreditCardPipeModule } from '@fleet/pipes';
import { MatIconModule } from '@angular/material/icon';
import { PaymentMethodIconModule } from '../payment-method-icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ReactiveFormsModule } from '@angular/forms';
import { BillingAddressComponent } from '../billing-address/billing-address.component';

@NgModule({
  declarations: [BraintreeHostedFieldsComponent],
  imports: [
    CommonModule,
    MatInputModule,
    MatFormFieldModule,
    CreditCardPipeModule,
    MatIconModule,
    PaymentMethodIconModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    BillingAddressComponent,
  ],
  exports: [BraintreeHostedFieldsComponent],
})
export class BraintreeHostedFieldsModule {}
