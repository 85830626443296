import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { PlaceModel } from '@fleet/model';
import { displayLinesFromAddress } from '@fleet/utilities';

import { LocationService, ReverseGeocodeState } from '../location.service';
import {
  provideTranslocoScope,
  TranslocoDirective,
  TranslocoService,
} from '@jsverse/transloco';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { SelectionListInDrawerModule } from '@fleet/layout';
import { AlertsFromIssuesModule } from '@fleet/issue';
import { FuseAlertModule } from '@fleet/fuse';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
const lazyTranslationloader = ['en_au', 'es', 'en_us'].reduce(
  (acc: { [key: string]: () => Promise<any> }, lang) => {
    acc[lang] = () => import(`./i18n/${lang}.json`);

    return acc;
  },
  {}
);
@Component({
  selector: 'fleet-reverse-geo-location',
  providers: [
    provideTranslocoScope({
      scope: 'reverseGeoLocation',
      loader: lazyTranslationloader,
    }),
  ],
  template: `
    <ng-container *transloco="let t; scope: 'reverseGeoLocation'">
      <fleet-selection-list-in-drawer
        [title]="t('reverseGeoLocation.title')"
        (cancel)="cancel.emit()"
      >
        <fuse-alert
          slot="error"
          *ngFor="let alert of reverseGeocodeState.issues | alertsFromIssues"
          class=""
          [appearance]="'outline'"
          [showIcon]="true"
          [type]="alert.type"
          [@shake]="alert.type === 'error'"
        >
          {{ alert.message }}
        </fuse-alert>

        <ng-container slot="list">
          <ng-container *ngIf="selectedPlace; else results">
            <form id="location-form" [formGroup]="form" class="w-full p-4">
              <div class="flex w-full flex-row gap-2">
                <mat-form-field class="fuse-mat-dense flex-auto">
                  <mat-label class="text-sm font-light">{{
                    t('reverseGeoLocation.unitNumber')
                  }}</mat-label>
                  <input
                    autocomplete="off"
                    data-lpignore="true"
                    data-form-type="other"
                    matInput
                    [placeholder]="t('reverseGeoLocation.unitNumber')"
                    formControlName="unitNumber"
                  />
                </mat-form-field>
                <mat-form-field class="fuse-mat-dense flex-auto">
                  <mat-label class="text-sm font-light">{{
                    t('reverseGeoLocation.streetNumber')
                  }}</mat-label>
                  <input
                    autocomplete="off"
                    data-lpignore="true"
                    data-form-type="other"
                    matInput
                    [placeholder]="t('reverseGeoLocation.streetNumber')"
                    formControlName="streetNumber"
                  />
                </mat-form-field>
              </div>

              <mat-form-field class="fuse-mat-dense w-full">
                <mat-label class="text-sm font-light">{{
                  t('reverseGeoLocation.streetName')
                }}</mat-label>
                <input
                  autocomplete="off"
                  data-lpignore="true"
                  data-form-type="other"
                  matInput
                  [placeholder]="t('reverseGeoLocation.streetName')"
                  formControlName="streetName"
                />
              </mat-form-field>

              <mat-form-field class="fuse-mat-dense w-full">
                <mat-label class="text-sm font-light">{{
                  t('reverseGeoLocation.suburb')
                }}</mat-label>
                <input
                  autocomplete="off"
                  data-lpignore="true"
                  data-form-type="other"
                  matInput
                  [placeholder]="t('reverseGeoLocation.suburb')"
                  formControlName="locality"
                />
              </mat-form-field>

              <div class="flex flex-row gap-2 w-full">
                <mat-form-field class="fuse-mat-dense flex-auto">
                  <mat-label>{{ t('reverseGeoLocation.state') }}</mat-label>
                  <input
                    autocomplete="off"
                    data-lpignore="true"
                    data-form-type="other"
                    matInput
                    [placeholder]="t('reverseGeoLocation.state')"
                    formControlName="regionCode"
                  />
                </mat-form-field>
                <mat-form-field class="fuse-mat-dense flex-auto">
                  <mat-label class="text-sm font-light">{{
                    t('reverseGeoLocation.postcode')
                  }}</mat-label>
                  <input
                    autocomplete="off"
                    data-lpignore="true"
                    data-form-type="other"
                    matInput
                    [placeholder]="t('reverseGeoLocation.postcode')"
                    formControlName="postalCode"
                  />
                </mat-form-field>
              </div>

              <div class="flex justify-end">
                <button
                  mat-flat-button
                  type="button"
                  class="bg-primary-600 text-white disabled:bg-gray-200"
                  [disabled]="form.invalid"
                  (click)="confirm()"
                >
                  {{ t('reverseGeoLocation.confirmButton') }}
                </button>
              </div>
            </form>
          </ng-container>
          <ng-template #results>
            <div class="text-lg p-4" *ngIf="!reverseGeocodeState?.latitude">
              {{ t('reverseGeoLocation.placeMarker') }}
            </div>
            <div
              *ngIf="reverseGeocodeState.loading"
              class="flex w-full items-center justify-center"
            >
              <mat-spinner matSuffix [diameter]="24"></mat-spinner>
            </div>

            <ng-container *ngIf="places?.length > 1">
              <div class="text-lg p-4">
                {{ t('reverseGeoLocation.multipleResults') }}
              </div>
              <div class="border-b-2 my-4"></div>
              <mat-selection-list class="selection-hide-checkboxes" #placesList>
                <mat-list-option
                  *ngFor="let place of places"
                  (click)="selectPlace(place)"
                  (keydown.enter)="selectPlace(place)"
                >
                  <span>{{
                    place.displayLine1 + ', ' + place.displayLine2
                  }}</span>
                </mat-list-option>
              </mat-selection-list>
            </ng-container>
          </ng-template>
        </ng-container>
      </fleet-selection-list-in-drawer>
    </ng-container>
  `,

  styles: [
    `
      .mat-pseudo-checkbox {
        display: none !important;
      }
    `,
  ],

  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    CommonModule,
    MatListModule,
    MatButtonModule,
    SelectionListInDrawerModule,
    AlertsFromIssuesModule,
    FuseAlertModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    TranslocoDirective,
  ],
})
export class ReverseGeoLocationComponent implements OnInit, OnDestroy {
  reverseGeocodeState: ReverseGeocodeState;
  selectedPlace: PlaceModel;
  places: PlaceModel[];
  form: UntypedFormGroup;
  @Output() reverseGeocodeConfirmed = new EventEmitter();
  @Output() cancel = new EventEmitter();
  constructor(
    private locationService: LocationService,
    private fb: UntypedFormBuilder,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.locationService.reverseGeocode$.subscribe({
      next: (state) => {
        this.reverseGeocodeState = state;

        this.changeDetectorRef.markForCheck();
      },
    });
    this.locationService.reverseGeocodeResult$.subscribe({
      next: (places) => {
        this.places = places;
        if (places.length === 1) {
          this.selectedPlace = places[0];
          this.form.patchValue(places[0].structuredAddress);
        }
      },
    });
  }

  buildForm(): void {
    this.form = this.fb.group({
      unitNumber: [null],
      streetNumber: [null],
      streetName: [null],
      locality: [null],
      regionCode: [null, Validators.required],
      postalCode: [null, Validators.required],
      country: [],
    });
  }

  confirm() {
    // this.locationService.setSelectedPlace(Object.assign({},this.form.value,{});
    const structuredAddress = this.form.value;
    const displayLines = displayLinesFromAddress(structuredAddress);
    this.reverseGeocodeConfirmed.emit({
      structuredAddress: this.form.value,
      displayLine1: displayLines.displayLine1 ? displayLines.displayLine1 : '',
      displayLine2: displayLines.displayLine2 ? displayLines.displayLine2 : '',
      latitude: this.reverseGeocodeState.latitude,
      longitude: this.reverseGeocodeState.longitude,
    });
  }

  selectPlace(place: PlaceModel) {
    if (!this.form) {
      this.buildForm();
    }
    this.form.patchValue(place.structuredAddress);
    this.selectedPlace = place;
  }

  ngOnDestroy(): void {
    this.locationService.resetReverseGeoState();
  }
}
