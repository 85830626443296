import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { fuseAnimations } from '@fleet/fuse';
import { RelatedEntitySearchLayoutComponent } from '@fleet/layout';
import {
  ApiResponse,
  DriverModel,
  JobModel,
  OperatorModel,
  OperatorUserModel,
  OrganisationModel,
  OrganisationUserModel,
  PaymentTransactionModel,
  TicketModel,
  TravellerModel,
  VehicleModel,
} from '@fleet/model';

import { Observable } from 'rxjs';
import { TicketCreateEditComponent } from '../../../ticket-create-edit/ticket-create-edit.component';
import { TicketLinkComponent } from '../../../ticket-link/ticket-link.component';
import { TicketSearchForm } from '../../ticket-search-form.service';
import { TicketSearchService } from '../../ticket-search.service';
import { TicketApiService } from '@fleet/api';

@Component({
  selector: 'fleet-ticket-search-container',
  templateUrl: './ticket-search-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class TicketSearchContainerComponent implements OnInit, OnDestroy {
  excludeType:
    | 'DRIVER'
    | 'JOB'
    | 'TRAVELLER'
    | 'ORGANISATION'
    | 'VEHICLE'
    | 'OPERATOR'
    | 'ORGANISATION_USER'
    | 'OPERATOR_USER';
  _driver: DriverModel;
  @Input() set driver(value: DriverModel) {
    this._driver = value;
    if (value) {
      this.ticketSearchService.searchTickets({
        driverId: value.driverId,
        limit: 100,
        offset: 0,
      });

      this.searchForm.get('driverId').patchValue(value.driverId);
      //Looking at POV from driver page - we want the external view link to be TRAVELLER
      this.excludeType = 'DRIVER';
    }
  }

  get driver() {
    return this._driver;
  }

  _vehicle: VehicleModel;
  @Input() set vehicle(value: VehicleModel) {
    this._vehicle = value;
    if (value) {
      this.ticketSearchService.searchTickets({
        vehicleId: value.vehicleId,
        limit: 100,
        offset: 0,
      });

      this.searchForm.get('vehicleId').patchValue(value.vehicleId);
      this.excludeType = 'VEHICLE';
    }
  }

  get vehicle() {
    return this._vehicle;
  }

  _traveller: TravellerModel;
  @Input() set traveller(value: TravellerModel) {
    this._traveller = value;
    if (value) {
      this.ticketSearchService.searchTickets({
        travellerId: value.travellerId,
        limit: 100,
        offset: 0,
      });

      this.searchForm.get('travellerId').patchValue(value.travellerId);
      //Looking at POV from traveller page - we want the external view link to be DRIVER
      this.excludeType = 'TRAVELLER';
    }
  }

  get traveller() {
    return this._traveller;
  }

  _job: JobModel;
  @Input() set job(value: JobModel) {
    this._job = value;
    if (value) {
      this.ticketSearchService.searchTickets({
        jobId: value.jobId,
        limit: 100,
        offset: 0,
      });

      this.searchForm.get('jobId').patchValue(value.jobId);
      this.excludeType = 'JOB';
    }
  }

  get job() {
    return this._job;
  }

  _organisation: OrganisationModel;
  @Input() set organisation(value: OrganisationModel) {
    this._organisation = value;
    if (value) {
      this.ticketSearchService.searchTickets({
        organisationId: value.organisationId,
        limit: 100,
        offset: 0,
      });

      this.searchForm.get('organisationId').patchValue(value.organisationId);
      this.excludeType = 'ORGANISATION';
    }
  }

  get organisation() {
    return this._organisation;
  }

  _operator: OperatorModel;
  @Input() set operator(value: OperatorModel) {
    this._operator = value;
    if (value) {
      this.ticketSearchService.searchTickets({
        operatorId: value.operatorId,
        limit: 100,
        offset: 0,
      });

      this.searchForm.get('operatorId').patchValue(value.operatorId);
      this.excludeType = 'OPERATOR';
    }
  }

  get operator() {
    return this._operator;
  }

  _operatorUser: OperatorUserModel;
  @Input() set operatorUser(value: OperatorUserModel) {
    this._operatorUser = value;
    if (value) {
      this.ticketSearchService.searchTickets({
        operatorUserId: value.operatorUserId,
        limit: 100,
        offset: 0,
      });

      this.searchForm.get('operatorUserId').patchValue(value.operatorUserId);
      this.excludeType = 'OPERATOR_USER';
    }
  }

  get operatorUser() {
    return this._operatorUser;
  }

  _organisationUser: OrganisationUserModel;
  @Input() set organisationUser(value: OrganisationUserModel) {
    this._organisationUser = value;
    if (value) {
      this.ticketSearchService.searchTickets({
        organisationUserId: value.organisationUserId,
        limit: 100,
        offset: 0,
      });

      this.searchForm
        .get('organisationUserId')
        .patchValue(value.organisationUserId);
      this.excludeType = 'ORGANISATION_USER';
    }
  }

  get organisationUser() {
    return this._organisationUser;
  }

  @Input() transaction: PaymentTransactionModel;
  search$: Observable<any>;

  paramLabelMap: any = {};
  @ViewChild('searchLayout', { static: false })
  searchLayout: RelatedEntitySearchLayoutComponent;

  constructor(
    private ticketSearchService: TicketSearchService,
    public searchForm: TicketSearchForm,
    private ticketApiService: TicketApiService,
    private router: Router,
    private matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.search$ = this.ticketSearchService.ticketSearchState$;

    this.paramLabelMap['fromDate'] = {
      dateFormat: 'D',
      label: 'From',
    };
    this.paramLabelMap['toDate'] = {
      dateFormat: 'D',
      label: 'To',
    };
  }

  selectTicket(ticket: TicketModel) {
    this.ticketApiService.getLink(ticket).subscribe({
      next: (resp: ApiResponse<TicketModel>) => {
        window.open(resp.data.externalTicketUrl, '_blank');
      },
      error: () => {
        window.open(ticket.externalTicketUrl, '_blank');
      },
    });
  }

  navigateEntity(value: { type: string; id: string }) {
    const url = value.type + '/' + value.id;

    this.router.navigate([url]);
  }

  searchTickets(params: any) {
    this.ticketSearchService.searchTickets(params);

    this.searchLayout.toggleSearchExpanded();
  }

  removeParamAndSearch(term: string) {
    this.ticketSearchService.removeParamAndSearch(term);
    this.searchForm.get(term).reset();
  }

  ngOnDestroy() {
    this.ticketSearchService.resetTicketSearchState();
    this.searchForm.reset();
  }

  editTicket(ticket: TicketModel) {
    const dialog = this.matDialog.open(TicketCreateEditComponent);

    if (this.driver) {
      dialog.componentInstance.driver = this.driver;
    }
    if (this.traveller) {
      dialog.componentInstance.traveller = this.traveller;
    }
    if (this.job) {
      dialog.componentInstance.job = this.job;
    }
    if (this.vehicle) {
      dialog.componentInstance.vehicle = this.vehicle;
    }

    dialog.componentInstance.ticket = ticket;

    dialog.componentInstance.cancelled.subscribe(() => {
      dialog.close();
    });

    dialog.componentInstance.successful.subscribe((ticket: TicketModel) => {
      dialog.close();
      if (this.driver) {
        this.ticketSearchService.searchTickets({
          driverId: this.driver.driverId,
          limit: 100,
          offset: 0,
        });
      }
      if (this.traveller) {
        this.ticketSearchService.searchTickets({
          travellerId: this.traveller.travellerId,
          limit: 100,
          offset: 0,
        });
      }
      if (this.job) {
        this.ticketSearchService.searchTickets({
          jobId: this.job.jobId,
          limit: 100,
          offset: 0,
        });
      }

      if (this.vehicle) {
        this.ticketSearchService.searchTickets({
          vehicleId: this.vehicle.vehicleId,
          limit: 100,
          offset: 0,
        });
      }

      if (this.operator) {
        this.ticketSearchService.searchTickets({
          operatorId: this.operator.operatorId,
          limit: 100,
          offset: 0,
        });
      }
      if (this.organisation) {
        this.ticketSearchService.searchTickets({
          organisationId: this.organisation.organisationId,
          limit: 100,
          offset: 0,
        });
      }
      if (this.operatorUser) {
        this.ticketSearchService.searchTickets({
          operatorUserId: this.operatorUser.operatorUserId,
          limit: 100,
          offset: 0,
        });
      }
      if (this.organisationUser) {
        this.ticketSearchService.searchTickets({
          organisationUserId: this.organisationUser.organisationUserId,
          limit: 100,
          offset: 0,
        });
      }
    });
  }

  createTicket() {
    const dialog = this.matDialog.open(TicketCreateEditComponent);

    if (this.driver) {
      dialog.componentInstance.driver = this.driver;
    }
    if (this.traveller) {
      dialog.componentInstance.traveller = this.traveller;
    }
    if (this.job) {
      dialog.componentInstance.job = this.job;
    }
    if (this.vehicle) {
      dialog.componentInstance.vehicle = this.vehicle;
    }
    if (this.operator) {
      dialog.componentInstance.operator = this.operator;
    }
    if (this.organisation) {
      dialog.componentInstance.organisation = this.organisation;
    }
    if (this.operatorUser) {
      dialog.componentInstance.operatorUser = this.operatorUser;
    }
    if (this.organisationUser) {
      dialog.componentInstance.organisationUser = this.organisationUser;
    }

    dialog.componentInstance.cancelled.subscribe(() => {
      dialog.close();
    });
    dialog.componentInstance.successful.subscribe((ticket: TicketModel) => {
      dialog.close();
      if (this.driver) {
        this.ticketSearchService.searchTickets({
          driverId: this.driver.driverId,
          limit: 100,
          offset: 0,
        });
      }
      if (this.traveller) {
        this.ticketSearchService.searchTickets({
          travellerId: this.traveller.travellerId,
          limit: 100,
          offset: 0,
        });
      }
      if (this.job) {
        this.ticketSearchService.searchTickets({
          jobId: this.job.jobId,
          limit: 100,
          offset: 0,
        });
      }

      if (this.vehicle) {
        this.ticketSearchService.searchTickets({
          vehicleId: this.vehicle.vehicleId,
          limit: 100,
          offset: 0,
        });
      }

      if (this.operator) {
        this.ticketSearchService.searchTickets({
          operatorId: this.operator.operatorId,
          limit: 100,
          offset: 0,
        });
      }
      if (this.organisation) {
        this.ticketSearchService.searchTickets({
          organisationId: this.organisation.organisationId,
          limit: 100,
          offset: 0,
        });
      }
      if (this.operatorUser) {
        this.ticketSearchService.searchTickets({
          operatorUserId: this.operatorUser.operatorUserId,
          limit: 100,
          offset: 0,
        });
      }
      if (this.organisationUser) {
        this.ticketSearchService.searchTickets({
          organisationUserId: this.organisationUser.organisationUserId,
          limit: 100,
          offset: 0,
        });
      }
    });
  }

  linkTicket(ticket: TicketModel) {
    const dialog = this.matDialog.open(TicketLinkComponent);

    dialog.componentInstance.ticket = ticket;

    if (this.driver) {
      dialog.componentInstance.driver = this.driver;
    }
    if (this.traveller) {
      dialog.componentInstance.traveller = this.traveller;
    }
    if (this.job) {
      dialog.componentInstance.job = this.job;
    }

    if (this.vehicle) {
      dialog.componentInstance.vehicle = this.vehicle;
    }

    if (this.operator) {
      dialog.componentInstance.operator = this.operator;
    }

    if (this.operatorUser) {
      dialog.componentInstance.operatorUser = this.operatorUser;
    }
    if (this.organisation) {
      dialog.componentInstance.organisation = this.organisation;
    }

    if (this.organisationUser) {
      dialog.componentInstance.organisationUser = this.organisationUser;
    }

    dialog.componentInstance.cancelled.subscribe(() => {
      dialog.close();
    });
    dialog.componentInstance.successful.subscribe((ticket: TicketModel) => {
      this.ticketSearchService.searchTickets({
        jobId: this.job.jobId,
        limit: 100,
        offset: 0,
      });

      dialog.close();
    });
  }
}
