import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { FuseCardModule } from '@fleet/fuse';
import { MarkedPipeModule, TotalFromFareGroupsPipeModule } from '@fleet/pipes';
import { JobCardFareDetailComponent } from './job-card-fare-detail.component';
import { FareGroupTotalsPipe } from './pipes/fare-group-totals.pipe';
import { FareGroupPipe } from './pipes/fare-group.pipe';
import { provideTranslocoScope, TranslocoDirective } from '@jsverse/transloco';

const lazyTranslationloader = ['en_au', 'es', 'en_us'].reduce(
  (acc: { [key: string]: () => Promise<any> }, lang) => {
    acc[lang] = () => import(`./i18n/${lang}.json`);

    return acc;
  },
  {}
);

@NgModule({
  declarations: [
    JobCardFareDetailComponent,
    FareGroupPipe,

    FareGroupTotalsPipe,
  ],
  imports: [
    CommonModule,
    FuseCardModule,
    MatButtonModule,
    RouterModule,
    MarkedPipeModule,
    TotalFromFareGroupsPipeModule,
    TranslocoDirective,
  ],
  exports: [JobCardFareDetailComponent],
  providers: [
    provideTranslocoScope({
      scope: 'jobCardFareDetail',
      loader: lazyTranslationloader,
    }),
  ],
})
export class JobCardFareDetailModule {}
