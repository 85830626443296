import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { paths } from '@fleet/environment';
import {
  ApiResponse,
  AreaModel,
  ConditionConfigurationModel,
  DispatchLogModel,
  MerchantIdentifierModel,
  NetworkModel,
  NetworkUserModel,
  OrganisationModel,
  PlanModel,
  PlanSearchResultModel,
  TaxiAuthorityConfigurationModel,
  VehicleModel,
} from '@fleet/model';
import { encodeParams, handleApiError } from '@fleet/utilities';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NetworkApiService {
  host: string;
  constructor(private http: HttpClient, @Inject('env') env: any) {
    this.host = env.host + paths.network;
  }

  signup(payload: any) {
    return this.http
      .post(`${this.host}/signup`, payload)
      .pipe(catchError(handleApiError));
  }

  getUser(
    networkId: string,
    networkUserId: string
  ): Observable<ApiResponse<NetworkUserModel> | any> {
    return this.http
      .get(`${this.host}/${networkId}/user/${networkUserId}`)
      .pipe(catchError(handleApiError));
  }

  addVehicleToService(
    networkId: string,
    networkServiceId: string,
    vehicle: VehicleModel
  ): Observable<ApiResponse<VehicleModel> | any> {
    return this.http
      .post(
        `${this.host}/${networkId}/service/${networkServiceId}/vehicle`,
        vehicle
      )
      .pipe(catchError(handleApiError));
  }

  addOrganisationToService(
    networkId: string,
    networkServiceId: string,
    organisation: OrganisationModel
  ): Observable<ApiResponse<VehicleModel> | any> {
    return this.http
      .post(
        `${this.host}/${networkId}/service/${networkServiceId}/organisation`,
        organisation
      )
      .pipe(catchError(handleApiError));
  }

  createMerchantIdentifier(
    networkId: string,
    merchantIdentifierModel: MerchantIdentifierModel
  ): Observable<ApiResponse<MerchantIdentifierModel> | any> {
    return this.http
      .post(
        `${this.host}/${networkId}/merchantIdentifier`,
        merchantIdentifierModel
      )
      .pipe(catchError(handleApiError));
  }

  createNetwork(
    network: NetworkModel
  ): Observable<ApiResponse<NetworkModel> | any> {
    return this.http
      .post(`${this.host}`, network)
      .pipe(catchError(handleApiError));
  }

  createUser(
    networkId: string,
    networkUser: NetworkUserModel
  ): Observable<ApiResponse<NetworkUserModel> | any> {
    return this.http
      .post(`${this.host}/${networkId}/user`, networkUser)
      .pipe(catchError(handleApiError));
  }

  getNetwork(networkId: string): Observable<ApiResponse<NetworkModel> | any> {
    return this.http
      .get(`${this.host}/${networkId}`)
      .pipe(catchError(handleApiError));
  }

  getPlan(
    networkId: string,

    networkFeatureSetId: string
  ): Observable<ApiResponse<PlanModel> | any> {
    return this.http
      .get(`${this.host}/${networkId}/networkFeatureSet/${networkFeatureSetId}`)
      .pipe(catchError(handleApiError));
  }

  searchMerchantIdentifiers(
    networkId: string,
    params: any
  ): Observable<ApiResponse<MerchantIdentifierModel[]> | any> {
    return this.http
      .get(`${this.host}/${networkId}/merchantIdentifier`, {
        observe: 'response',
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  searchNetworks(
    params: any
  ): Observable<HttpResponse<ApiResponse<NetworkModel[]>> | any> {
    return this.http
      .get(`${this.host}/`, {
        observe: 'response',
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  searchPlans(
    networkId: string,
    params: any
  ): Observable<HttpResponse<ApiResponse<PlanSearchResultModel[]>> | any> {
    return this.http
      .get(`${this.host}/${networkId}/plan`, {
        observe: 'response',
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  searchNetworkServices(networkId: string, params: any): Observable<any> {
    return this.http
      .get(`${this.host}/${networkId}/service`, {
        observe: 'response',
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  searchUsers(
    networkId: string,
    params: any
  ): Observable<ApiResponse<NetworkUserModel[]> | any> {
    return this.http
      .get(`${this.host}/${networkId}/user`, {
        observe: 'response',
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  updateNetwork(
    networkId: string,
    network: NetworkModel
  ): Observable<ApiResponse<NetworkModel> | any> {
    return this.http
      .put(`${this.host}/${networkId}`, network)
      .pipe(catchError(handleApiError));
  }

  getUserRoles(networkId: string, networkUserId: string) {
    return this.http
      .get(`${this.host}/${networkId}/user/${networkUserId}/role`)
      .pipe(catchError(handleApiError));
  }

  updateUser(
    networkId: string,
    networkUserId: string,
    networkUser: NetworkUserModel
  ): Observable<ApiResponse<NetworkModel> | any> {
    return this.http
      .put(`${this.host}/${networkId}/user/${networkUserId}`, networkUser)
      .pipe(catchError(handleApiError));
  }

  searchNetworkConditionConfiguration(
    params: any,
    networkId: string
  ): Observable<ApiResponse<ConditionConfigurationModel[]> | any> {
    return this.http
      .get(`${this.host}/${networkId}/conditionConfiguration`, {
        observe: 'response',
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  getPlanDetail(networkId: string, planId: string, featureSetId: string) {
    return this.http
      .get(
        `${this.host}/${networkId}/plan/${planId}/featureSet/${featureSetId}`
      )
      .pipe(catchError(handleApiError));
  }

  resendInvitation(
    networkUserId: string,
    networkId: string
  ): Observable<ApiResponse<NetworkUserModel> | any> {
    return this.http
      .post(`${this.host}/${networkId}/user/${networkUserId}/invite`, {})
      .pipe(catchError(handleApiError));
  }

  removeNetworkUser(
    networkUserId: string,
    networkId: string
  ): Observable<null | any> {
    return this.http
      .delete(`${this.host}/${networkId}/user/${networkUserId}`)
      .pipe(catchError(handleApiError));
  }

  removeVehicleFromService(
    networkId: string,
    networkServiceId: string,
    vehicleId: string
  ) {
    return this.http
      .delete(
        `${this.host}/${networkId}/service/${networkServiceId}/vehicle/${vehicleId}`
      )
      .pipe(catchError(handleApiError));
  }

  removeOrganisationFromService(
    networkId: string,
    networkServiceId: string,
    organisationId: string
  ) {
    return this.http
      .delete(
        `${this.host}/${networkId}/service/${networkServiceId}/organisation/${organisationId}`
      )
      .pipe(catchError(handleApiError));
  }

  searchDispatchAreas(
    networkId: string,
    params: any
  ): Observable<HttpResponse<ApiResponse<AreaModel[]>>> | any {
    return this.http
      .get(`${this.host}/${networkId}/dispatcharea`, {
        observe: 'response',
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  searchDispatchPolicies(
    networkId: string,
    params: any
  ): Observable<HttpResponse<ApiResponse<AreaModel[]>>> | any {
    return this.http
      .get(`${this.host}/${networkId}/dispatchpolicy`, {
        observe: 'response',
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  searchRanks(
    networkId: string,
    params: any
  ): Observable<HttpResponse<ApiResponse<AreaModel[]>>> | any {
    return this.http
      .get(`${this.host}/${networkId}/rank`, {
        observe: 'response',
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  searchServiceAreas(
    networkId: string,
    params: any
  ): Observable<HttpResponse<ApiResponse<AreaModel[]>>> | any {
    return this.http
      .get(`${this.host}/${networkId}/servicearea`, {
        observe: 'response',
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  searchNetworkConfigurationTaxiAuthorities(
    params: any
  ): Observable<
    HttpResponse<ApiResponse<TaxiAuthorityConfigurationModel[]>> | any
  > {
    return this.http
      .get(`${this.host}/configuration/taxiAuthority`, {
        observe: 'response',
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  getNetworkConfigurationTaxiAuthority(networkConfiguationAuthorityId: string) {
    return this.http
      .get(
        `${this.host}/configuration/taxiAuthority/${networkConfiguationAuthorityId}`
      )
      .pipe(catchError(handleApiError));
  }
  searchServiceConfigurations(
    params: any
  ): Observable<HttpResponse<ApiResponse<any[]>> | any> {
    return this.http
      .get(`${this.host}/configuration/service`, {
        observe: 'response',
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }
  searchProductConfigurations(
    params: any
  ): Observable<HttpResponse<ApiResponse<any[]>> | any> {
    return this.http
      .get(`${this.host}/configuration/product`, {
        observe: 'response',
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  searchDispatchLogs(
    networkId: string,
    params: any
  ): Observable<ApiResponse<DispatchLogModel[]> | any> {
    return this.http
      .get(`${this.host}/${networkId}/dispatchlog`, {
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  disableMfa(networkId: string, networkUserId: string): Observable<any> {
    return this.http
      .post(`${this.host}/${networkId}/user/${networkUserId}/disableMfa`, {})
      .pipe(catchError(handleApiError));
  }

  enableMfa(
    networkId: string,
    networkUserId: string
  ): Observable<HttpResponse<ApiResponse<any[]>> | any> {
    return this.http
      .post(
        `${this.host}/${networkId}/user/${networkUserId}/enableMfa`,
        {},
        { observe: 'response' }
      )
      .pipe(catchError(handleApiError));
  }
}
