import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiResponse } from '@fleet/model';
import { paths } from '@fleet/environment';
import { encodeParams, handleApiError } from '@fleet/utilities';

@Injectable({
  providedIn: 'root',
})
export class StripeApiService {
  host: string;

  integrationHost: string;
  constructor(private http: HttpClient, @Inject('env') env: any) {
    this.host = env.host + paths.stripe;
    this.integrationHost = env.host + paths.stripeIntegration;
  }

  /**
   * Return the public key for the stripe account
   *
   * @return Observable<string>
   */
  getPublicKey(): Observable<any> {
    return this.http
      .get<string>(`${this.host}/publicKey`)
      .pipe(catchError(handleApiError));
  }

  /**
   * Complete the setup intent that requires action
   *
   * @param paymentMethodModel
   * @return Observable<any>
   */
  setupIntentRequiresActionComplete(paymentMethodModel: any): Observable<any> {
    return this.http
      .put<any>(
        `${this.host}/setupIntent/requiresAction/complete`,
        paymentMethodModel
      )
      .pipe(catchError(handleApiError));
  }

  complete3DSAuthentication(paymentMethodModel: any): Observable<any> {
    return this.http
      .put<any>(`${this.host}/complete3DSAuthentication`, paymentMethodModel)
      .pipe(catchError(handleApiError));
  }

  searchStripeConfigurations(params: any): Observable<any> {
    return this.http
      .get(`${this.integrationHost}/search`, {
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  getStripeConfiguration(stripeConfigurationId: string): Observable<any> {
    return this.http
      .get(`${this.integrationHost}/${stripeConfigurationId}`)
      .pipe(catchError(handleApiError));
  }

  sync(stripeConfigurationId: string): Observable<any> {
    return this.http
      .post(`${this.integrationHost}/${stripeConfigurationId}/sync`, {})
      .pipe(catchError(handleApiError));
  }

  install(): Observable<any> {
    return this.http
      .get(`${this.integrationHost}/install`)
      .pipe(catchError(handleApiError));
  }

  deleteConfiguration(stripeConfigurationId: string): Observable<any> {
    return this.http
      .delete(`${this.integrationHost}/${stripeConfigurationId}`)
      .pipe(catchError(handleApiError));
  }

  refreshToken(stripeConfigurationId: string): Observable<any> {
    return this.http
      .post(`${this.integrationHost}/${stripeConfigurationId}/refreshToken`, {})
      .pipe(catchError(handleApiError));
  }
  suspendConfiguration(stripeConfigurationId: string): Observable<any> {
    return this.http
      .post(`${this.integrationHost}/${stripeConfigurationId}/suspend`, {})
      .pipe(catchError(handleApiError));
  }

  activateConfiguration(stripeConfigurationId: string): Observable<any> {
    return this.http
      .post(`${this.integrationHost}/${stripeConfigurationId}/activate`, {})
      .pipe(catchError(handleApiError));
  }

  searchStripeKeys(params: any): Observable<any> {
    return this.http
      .get(`${this.integrationHost}/key`, {
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  createStripeKey(payload: any): Observable<any> {
    return this.http
      .post(`${this.integrationHost}/key`, payload)
      .pipe(catchError(handleApiError));
  }

  updateStripeKey(intergrationId: string, payload: any): Observable<any> {
    return this.http
      .put(`${this.integrationHost}/key/${intergrationId}`, payload)
      .pipe(catchError(handleApiError));
  }

  deleteStripeKey(intergrationId: string): Observable<any> {
    return this.http
      .delete(`${this.integrationHost}/key/${intergrationId}`)
      .pipe(catchError(handleApiError));
  }
}
