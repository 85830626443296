import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
  OnChanges,
  SimpleChanges,
  ElementRef,
  Renderer2,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';

import { InjectionToken } from '@angular/core';

export const DEFAULT_BUTTON_CONFIG = new InjectionToken<ButtonConfig>(
  'defaultButtonConfig'
);

export interface ButtonConfig {
  variant: 'rounded' | 'standard';
  // Add more properties if needed
}

const defaultSizeClasses = 'px-3 py-1.5 text-sm';
const svgWidth = 20; // Assuming the SVG width is 16px for the loading icon
const defaultColorClasses =
  'bg-primary-600 text-white hover:bg-primary-500 focus-visible:outline-primary-600';
const defaultVariantClass = 'rounded-full';
const coloredSpinnerBackground = '#E5E7EB';
const coloredSpinnerFill = 'currentColor';
@Component({
  selector: 'fleet-button',
  standalone: true,
  imports: [CommonModule],
  template: `<button
    [ngClass]="[sizeClasses, colorClasses, variantClasses]"
    type="button"
    class=" items-center font-semibold shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
    [style.minWidth.px]="minWidth"
  >
    <svg
      *ngIf="loading"
      aria-hidden="true"
      role="status"
      class="inline w-4 h-4 me-1 mx-auto  animate-spin leading-none"
      viewBox="0 0 100 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
        [style.fill]="spinnerBackground"
      />
      <path
        [style.fill]="spinnerFill"
        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
      />
    </svg>
    <span *ngIf="!loading || loadingLabel" class="truncate">
      {{ loading ? loadingLabel : label }}</span
    >
  </button>`,

  styles: [
    `
      .spinner-fill {
        fill: var(--fuse-primary-500);
      }
    `,
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements OnChanges, AfterViewInit {
  @Input() variant: 'rounded' | 'standard'; // Add more variants if needed
  @Input() loading = false;
  @Input() loadingLabel: string | null = '... Loading';
  @Input() label: string;
  @Input() size: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  @Input() color = 'primary';
  sizeClasses = defaultSizeClasses;
  variantClasses = defaultVariantClass;
  colorClasses = defaultColorClasses;
  spinnerFill = 'var(--fuse-primary-500)';
  spinnerBackground = 'text-gray-200 dark:text-gray-600';
  minWidth: number;

  constructor(
    private elRef: ElementRef,
    private renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    this.setMinWidth();

    // setInterval(() => {
    //   this.loading = !this.loading;
    //   this.changeDetectorRef.markForCheck();
    // }, 4000);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.size && changes.variant) {
      this.setSizeAndVariantClasses();
    }
    if (changes.color) {
      this.setColorClasses();
    }
    if (changes.label || changes.loading) {
      setTimeout(() => {
        this.setMinWidth();
      }, 20);
    }
  }

  private setMinWidth(): void {
    if (this.elRef.nativeElement) {
      const buttonElement: HTMLButtonElement =
        this.elRef.nativeElement.querySelector('button');
      if (buttonElement) {
        this.minWidth =
          buttonElement.clientWidth +
          (this.loading && this.loadingLabel == null ? svgWidth : 0);
        this.changeDetectorRef.markForCheck();
      }
    }
  }

  private setSizeAndVariantClasses(): void {
    switch (this.size) {
      case 'xs':
        this.sizeClasses = 'px-2.5 py-1 text-xs';
        break;
      case 'sm':
        this.sizeClasses = 'px-2.5 py-1 text-sm';
        break;
      case 'md':
        this.sizeClasses = 'px-3 py-1.5 text-sm';
        break;
      case 'lg':
        this.sizeClasses = 'px-3.5 py-2 text-sm';
        break;
      case 'xl':
        this.sizeClasses = 'px-4 py-2.5 text-sm';
        break;
      default:
        this.sizeClasses = defaultSizeClasses;
    }
    switch (this.variant) {
      case 'rounded':
        this.sizeClasses += ' rounded-full';

        break;
      case 'standard':
        {
          if (this.size === 'xs' || this.size === 'sm') {
            this.sizeClasses += ' rounded';
          } else {
            this.sizeClasses += ' rounded-md';
          }
        }
        break;
      default:
        this.variantClasses = defaultVariantClass;
    }
  }

  private setColorClasses(): void {
    switch (this.color) {
      case 'primary':
        this.colorClasses = defaultColorClasses;
        this.spinnerBackground = coloredSpinnerBackground;
        this.spinnerFill = coloredSpinnerFill;
        break;
      case 'amber':
        this.colorClasses =
          'bg-amber-500 text-white hover:bg-amber-400 focus-visible:outline-amber-500';
        this.spinnerBackground = coloredSpinnerBackground;
        this.spinnerFill = coloredSpinnerFill;
        break;
      case 'secondary':
        this.colorClasses =
          'bg-white text-gray-900 hover:bg-gray-50 ring-1 ring-inset ring-gray-300';
        this.spinnerBackground = 'text-gray-200 dark:text-gray-600';
        this.spinnerFill = 'var(--fuse-primary-500)';
        break;
    }
    this.changeDetectorRef.markForCheck();
    // This method can be implemented if needed to handle dynamic changes of color classes
  }
}
