import { HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  DriverApiService,
  NetworkApiService,
  NetworkGroupApiService,
} from '@fleet/api';
import { AuthService } from '@fleet/auth';
import { LocaleService } from '@fleet/locale';

import {
  ApiResponse,
  AreaModel,
  NetworkGroupModel,
  ReviewModel,
} from '@fleet/model';
import { ProductConfigurationService } from '@fleet/product-configuration';
import { Settings } from 'luxon';

import { BehaviorSubject, combineLatest, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NetworkGroupService {
  selectedGroup: BehaviorSubject<NetworkGroupModel> = new BehaviorSubject(null);
  outstandingDriverReviews: BehaviorSubject<ReviewModel[]> =
    new BehaviorSubject([]);

  serviceArea: BehaviorSubject<AreaModel> = new BehaviorSubject(null);

  constructor(
    private driverApiService: DriverApiService,
    private networkGroupApiService: NetworkGroupApiService,
    private networkApiService: NetworkApiService,
    private productConfigService: ProductConfigurationService,
    private localeService: LocaleService,
    // private authService: AuthService,
    @Inject('env') env: any
  ) {
    //if traveller product then fetch network group
    //combine latest with user
    // combineLatest([
    //   this.productConfigService.config$,
    //   // this.authService.user$,
    // ]).subscribe(([config]) => {
    //   if (
    //     env.fleetProduct === 'TRAVELLER' &&
    //     config &&
    //     config.networkGroupId &&
    //     config.networkId
    //   ) {
    //     //fetch network group
    //     console.log(
    //       'Traveller App fetching network group based on product config'
    //     );
    //     this.getNetworkGroup(config.networkGroupId, config.networkId);
    //   }
    // });
    // this.productConfigService.config$.subscribe({
    //   next: (config: any) => {
    //     if (
    //       env.fleetProduct === 'TRAVELLER' &&
    //       config &&
    //       config.networkGroupId &&
    //       config.networkId
    //     ) {
    //       //fetch network group
    //       console.log(
    //         'Traveller App fetching network group based on product config'
    //       );
    //       this.getNetworkGroup(config.networkGroupId, config.networkId);
    //     }
    //   },
    // });
  }
  setSelectedGroup(group: NetworkGroupModel) {
    Settings.defaultZone = group.timezone;
    Settings.defaultLocale = group.locale;

    //setting locale service
    if (group.locale) {
      this.localeService.setLocale(group.locale);
    }

    this.selectedGroup.next(group);

    if (group && group.type === 'TAXI_NETWORK') {
      //fetch service area (for later use in job module's map on initalise)
      this.networkApiService
        .searchServiceAreas(group.networkId, {
          networkGroupId: group.networkGroupId,
          includePolygon: true,
        })
        .subscribe({
          next: (resp: HttpResponse<ApiResponse<AreaModel[]>> | any) => {
            this.serviceArea.next(
              resp.body.data.length > 0 ? resp.body.data[0] : null
            );
          },
        });
    }
  }

  getNetworkGroup(networkGroupId: string, networkId: string) {
    this.networkGroupApiService
      .getNetworkGroup(networkGroupId, networkId)
      .subscribe({
        next: (resp: ApiResponse<NetworkGroupModel>) => {
          //HACK FOR GENERIC
          // this.setSelectedGroup({
          //   ...resp.data,
          //   displayName: 'Taxii Perth',
          // });
          this.setSelectedGroup(resp.data);
        },
      });
  }

  get networkGroup$() {
    return this.selectedGroup.asObservable();
  }

  get networkGroupId() {
    return this.selectedGroup.value
      ? this.selectedGroup.value.networkGroupId
      : null;
  }

  get regionCode() {
    return this.selectedGroup.value
      ? this.selectedGroup.value.regionCode
      : null;
  }

  get networkId() {
    return this.selectedGroup.value ? this.selectedGroup.value.networkId : null;
  }

  get serviceArea$() {
    return this.serviceArea.asObservable();
  }

  get networkGroupAndServiceArea$() {
    return combineLatest([this.networkGroup$, this.serviceArea$]).pipe(
      map(([group, area]) => {
        return { networkGroup: group, serviceArea: area };
      })
    );
  }

  get networkGroupAndServiceArea() {
    return {
      networkGroup: this.selectedGroup.value,
      serviceArea: this.serviceArea.value,
    };
  }

  get searchLatLngAndCountryCode$() {
    return this.networkGroupAndServiceArea$.pipe(
      map((groupAndArea: any) => {
        if (groupAndArea.networkGroup && groupAndArea.serviceArea) {
          return {
            latitude: groupAndArea.serviceArea.point.latitude,
            longitude: groupAndArea.serviceArea.point.longitude,

            countryCode: groupAndArea.networkGroup.countryCode,
          };
        } else {
          return null;
        }
      })
    );
  }

  fetchGroupState(networkGroupId: string, networkId: string) {
    const calls = [
      this.driverApiService.searchReviews({
        networkGroupId: networkGroupId,
        networkId: networkId,
        status: 'PENDING,IN_PROGRESS',
      }),
    ];
    forkJoin(calls).subscribe({
      next: ([reviewResp]) => {
        this.outstandingDriverReviews.next(reviewResp.data);
      },
    });
  }

  get networkGroupTimezone() {
    return this.selectedGroup.value && this.selectedGroup.value.timezone
      ? this.selectedGroup.value.timezone
      : null;
  }
}
