import { CurrencyPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { LocaleService } from '@fleet/locale';
import {
  PaymentMethodModel,
  PaymentMethodSearchResultModel,
} from '@fleet/model';
import { TitleCaseAndCleanPipe } from '@fleet/pipes';
import { internationalizedDate, titleCaseClean } from '@fleet/utilities';
import { DateTime } from 'luxon';
@Component({
  selector: 'fleet-payment-method-list-item',
  templateUrl: './payment-method-list-item.component.html',
  styleUrls: ['./payment-method-list-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentMethodListItemComponent implements OnInit {
  // titleCaseAndClean = new TitleCaseAndCleanPipe();
  currencyPipe: CurrencyPipe;
  @Input() disableActions = false;
  config: {
    paymentType: string;
    cardType: string;
    displayOne: string;
    displayTwo: string;
    detailsOne: string;
    detailsTwo: string;
    detailsThree: string;
    detailsFour: string;
    date: DateTime;
    status: string;
    secondaryStatus: string;
    icons: boolean;
    verificationInfo?: {
      cardVerificationStatus: string;
      cardVerificationType: string;
      cardVerified: string;
      cardThreeDsEnrolled: string;
    };
  };
  @Input() isDefault: boolean;
  _paymentMethod: PaymentMethodSearchResultModel | PaymentMethodModel | any;
  @Input() set paymentMethod(
    value: PaymentMethodSearchResultModel | PaymentMethodModel | any
  ) {
    this._paymentMethod = value;
    this.currencyPipe = new CurrencyPipe(this.localeService.getLocale());
    if (value) {
      this.config = {
        paymentType: null,
        cardType: null,
        displayOne: null,
        displayTwo: null,
        detailsOne: null,
        detailsTwo: null,
        detailsThree: null,
        detailsFour: null,
        date: null,
        status: null,
        secondaryStatus: null,
        icons: true,
      };
      this.changeDetectorRef.markForCheck();
      if (value.paymentType) {
        this.config.paymentType = value.paymentType;
      }
      if (value.type) {
        this.config.paymentType = value.type;
      }
      switch (this.config.paymentType) {
        case 'STORED_CARD':
          if (value.card && value.card?.cardType) {
            this.config.cardType = value.card?.cardType;
            this.config.secondaryStatus = value.card?.status;
            this.config.status = value.status;
            if (this.mode === 'condensed' || this.mode === 'condensed_2') {
              this.config.displayOne = value.card.maskedCardNumber;
            } else {
              this.config.displayOne = value.card?.cardholderName;
            }
          } else {
            this.config.cardType = value.cardType;
            this.config.secondaryStatus = value.cardStatus;
            this.config.status = value.status;
            if (this.mode === 'condensed' || this.mode === 'condensed_2') {
              this.config.displayOne = value.maskedCardNumber;
            } else {
              this.config.displayOne = value.cardholderName;
            }
            this.config.verificationInfo = {
              cardVerificationStatus: value.cardVerificationStatus,
              cardVerificationType: value.cardVerificationType,
              cardVerified: value.cardVerified
                ? DateTime.fromISO(value.cardVerified).toFormat('ff')
                : null,
              cardThreeDsEnrolled:
                value.cardThreeDsEnrolled === 'Y' ? 'Yes' : 'No',
            };
          }
          this.config.detailsOne = value.description;
          this.config.icons = true;
          if (value.organisationId) {
            this.config.displayOne = value.displayName;
            if (value.card && value.card?.cardType) {
              this.config.displayTwo = value.card?.cardholderName;
            } else {
              this.config.displayTwo = value.cardholderName;
            }
          }
          break;
        case 'ORGANISATION_ACCOUNT':
          if (value.account && value.account.accountName) {
            this.config.displayOne = value.displayName;
            this.config.displayTwo = value.contractName;
          } else {
            this.config.displayOne = value.displayName;
            this.config.displayTwo = value.contractName;
          }
          this.config.status = value.status;
          this.config.icons = true;
          break;
        case 'DIRECT_DEBIT_BANK_ACCOUNT':
          if (value.account && value.account.accountName) {
            this.config.displayOne = value.displayName;
            this.config.displayTwo = value.account.accountName;
            this.config.detailsOne = value.account.bsb;
            this.config.detailsTwo = value.account.accountNumber;
          } else {
            this.config.displayOne = value.displayName;
            this.config.displayTwo = value.accountName;
            this.config.detailsOne = 'BSB: ' + value.bsb;
            this.config.detailsTwo = 'Acc: ' + value.accountNumber;
          }
          this.config.icons = true;
          this.config.status = value.status;
          break;
        case 'SETTLEMENT_BANK_ACCOUNT':
          //thgere is a balance and a next seettlement date and settlement frequency
          if (value.account && value.account.accountName) {
            const nextSettlementDate = internationalizedDate(
              value.account.nextSettlementDate,
              'DATETIME_SHORT',
              false,
              null,
              this.localeService.getLocale()
            );
            this.config.displayOne = value.account.accountName;
            this.config.displayTwo =
              'Balance: ' +
              this.currencyPipe.transform(value.account.accountBalance);
            this.config.detailsOne = value.account.bsb;
            this.config.detailsTwo = value.account.accountNumber;

            this.config.detailsThree =
              'Next settlement is on ' + nextSettlementDate;
            this.config.detailsFour =
              titleCaseClean(value.account.settlementFrequency) + ' settlement';
          } else {
            const nextSettlementDate = internationalizedDate(
              value.nextSettlementDate,
              'DATETIME_SHORT',
              false,
              null,
              this.localeService.getLocale()
            );
            this.config.displayOne = value.accountName;
            this.config.displayTwo =
              'Balance: ' + this.currencyPipe.transform(value.accountBalance);
            this.config.detailsOne = 'BSB: ' + value.bsb;
            this.config.detailsTwo = 'Acc: ' + value.accountNumber;
            this.config.detailsThree =
              'Next settlement is on ' + nextSettlementDate;
            this.config.detailsFour =
              titleCaseClean(value.settlementFrequency) + ' settlement';
          }
          this.config.icons = false;
          this.config.status = value.status;
          break;
        case 'CASH':
          this.config.displayOne = 'Cash Payments';
          this.config.detailsOne = value.description;
          break;
        case 'BANK_DEPOSIT':
          this.config.displayOne = 'Bank Deposit';
          this.config.detailsOne = value.description;
          break;
        case 'OTHER':
          this.config.displayOne = 'Pay Driver';
          break;
        default:
          this.config.displayOne = value.displayName;
          this.config.detailsOne = value.description;
          break;
      }
      this.changeDetectorRef.markForCheck();
    }
  }
  get paymentMethod() {
    return this._paymentMethod;
  }
  @Output() actionSelected = new EventEmitter();
  @Output() cardSelected = new EventEmitter();
  @Input() actions: { label: string; value: string }[];
  @Input() mode: 'card' | 'list' | 'condensed' | 'condensed_2' = 'list';
  @Input() hasError = false;
  @Input() shadow = true;
  @Input() padding = true;
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private localeService: LocaleService
  ) {}
  ngOnInit(): void {}
}
