<fleet-dialog-layout
  [title]="ticket ? 'Update ticket' : 'Create Ticket'"
  [instructions]="ticket ? 'Update a ticket' : 'Create a ticket'"
>
  <form
    slot="main"
    *ngIf="form"
    [formGroup]="form"
    class="flex flex-col gap-2 w-full"
  >
    <mat-form-field>
      <mat-label>Category group</mat-label>

      <mat-select formControlName="group">
        <mat-option
          *ngFor="let categoryGroup of ticketCategoryGroups$ | async"
          [value]="categoryGroup.code"
        >
          {{ categoryGroup.displayValue | titleCaseAndClean }}
        </mat-option>
      </mat-select>
      <mat-error>Category group is required</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Category type</mat-label>

      <mat-select formControlName="ticketCategoryId">
        <mat-option
          *ngFor="let category of ticketCategories"
          [value]="category.ticketCategoryId"
        >
          {{ category.name }}
        </mat-option>
      </mat-select>
      <mat-error>Category type is required</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Subject</mat-label>
      <input matInput formControlName="subject" placeholder="Subject" />
      <mat-error>Subject must be provided</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Content</mat-label>
      <textarea
        matInput
        formControlName="content"
        placeholder="Content"
      ></textarea>
      <mat-error>Content must be provided</mat-error>
    </mat-form-field>

    <div *ngIf="selectedTicketAssociations?.length > 0">
      <mat-label>Associations</mat-label>
      <ng-container>
        <div
          *ngFor="let association of selectedTicketAssociations; let i = index"
        >
          <mat-checkbox
            [checked]="association?.included"
            [disabled]="association?.disabled"
            (change)="
              handleTicketAssociationChange(
                association.ticketAssociation,
                $event.checked,
                i
              )
            "
          >
            {{ association.ticketAssociation.type | titleCaseAndClean }}
          </mat-checkbox>
        </div>
      </ng-container>
    </div>
  </form>

  <fleet-progress-button
    slot="action"
    [state]="{ loading: loading, buttonLabel: ticket ? 'Update' : 'Save' }"
    [disabled]="!form.valid || loading"
    (onClick)="submit()"
  >
  </fleet-progress-button>

  <fuse-alert
    slot="error"
    *ngFor="let alert of issues | alertsFromIssues"
    class=""
    [appearance]="'outline'"
    [showIcon]="true"
    [type]="alert.type"
    [@shake]="alert.type === 'error'"
  >
    {{ alert.message }}
  </fuse-alert>
</fleet-dialog-layout>
