<ng-container *transloco="let t">
  <fleet-related-entity-search-layout
    [resultEntityType]="t('ticketSearchContainer.tickets')"
    [searchData]="search"
    #searchLayout
    *ngIf="search$ | async as search"
    class="w-full flex"
    [requiresPadding]="true"
    [title]="t('ticketSearchContainer.title')"
  >
    <ng-container slot="progress-bar">
      <mat-progress-bar
        *ngIf="search.searching"
        [mode]="'indeterminate'"
      ></mat-progress-bar>
    </ng-container>

    <ng-container slot="expanded-search">
      <fleet-ticket-search-form
        (search)="searchTickets($event)"
        [driver]="driver"
        [organisation]="organisation"
        [operator]="operator"
      >
      </fleet-ticket-search-form>
    </ng-container>

    <ng-container slot="search-chips">
      <fleet-search-chips-list
        *ngIf="search.pageData"
        [disabled]="search.searching ? true : false"
        (removeSearchTerm)="removeParamAndSearch($event)"
        [searchParams]="
          search.pageData.params
            | removeProperties
              : [
                  'driverId',
                  'networkId',
                  'networkGroupId',
                  'sort',
                  'travellerId',
                  'jobId',
                  'vehicleId',
                  'organisationId',
                  'organisationUserId',
                  'operatorId',
                  'operatorUserId'
                ]
        "
        [paramLabelMap]="paramLabelMap"
      ></fleet-search-chips-list>
    </ng-container>

    <div slot="error">
      <fuse-alert
        *ngFor="let alert of search.issues | alertsFromIssues"
        class=""
        [appearance]="'outline'"
        [showIcon]="true"
        [type]="alert.type"
        [@shake]="alert.type === 'error'"
      >
        {{ alert.message }}
      </fuse-alert>
    </div>
    <ng-container slot="actions">
      <button
        mat-flat-button
        class="bg-primary-600 text-white disabled:bg-gray-200"
        (click)="createTicket()"
        *ngIf="'TICKET_DETAIL:create' | hasFunction"
      >
        <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
        <span class="hidden ml-2 sm:flex">{{
          t('ticketSearchContainer.createTicket')
        }}</span>
      </button>

      <button
        mat-flat-button
        class="bg-primary-600 text-white disabled:bg-gray-200"
        (click)="linkTicket(null)"
        *ngIf="'TICKET_LINK:execute' | hasFunction"
      >
        <mat-icon [svgIcon]="'heroicons_outline:link'"></mat-icon>
        <span class="hidden ml-2 sm:flex">{{
          t('ticketSearchContainer.linkTicket')
        }}</span>
      </button>
    </ng-container>

    <fleet-ticket-search-results
      [excludeType]="excludeType"
      slot="results"
      [allowLinking]="job ? true : false"
      #searchResults
      class="flex flex-grow overflow-y-auto"
      [search]="search"
      (selectTicket)="selectTicket($event)"
      (linkTicket)="linkTicket($event)"
      (editTicket)="editTicket($event)"
      (navigateEntity)="navigateEntity($event)"
    >
    </fleet-ticket-search-results>
  </fleet-related-entity-search-layout>
</ng-container>
